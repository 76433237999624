.pagination {
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}
.page-dots,
.page {
  background-color: green;
  color: white;
  padding: 5px 5px;
  width: 25px;

  text-align: center;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.page-dots {
  background-color: grey;
  cursor: default;
}
.page:hover {
  background-color: rgb(30, 145, 30);
}
.current-page {
  background-color: grey;
  color: white;
  padding: 5px 5px;
  width: 25px;

  text-align: center;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.current-page:hover {
  background-color: rgb(155, 150, 150);
}
