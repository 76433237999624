.info-form {
  display: grid;
  grid-template-columns: 50% 50%;
  height: auto;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  margin-top: 30px;
  border-radius: 15px;
}
.info-form:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.date-picker {
  color: green;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  margin-top: 40px;
  margin-left: 10%;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
.date-picker2 {
  padding: 2px;
  font-weight: bold;

  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  padding-left: 10px;
  border-color: rgb(235, 232, 232);
  border-style: double;
  margin-top: 5px;
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
  border-radius: 5px;
  box-shadow: inset 0 3px 20px rgba(0, 0, 0, 0.1);
  width: 75%;
}
.date-picker2:disabled {
  background-color: rgb(218, 221, 216);
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
.info-form p {
  font-size: 25px;
  text-align: center;
  padding-top: 20px;
  color: rgb(126, 128, 125);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.form-info-input {
  margin-left: 10%;
  height: 25px;
  width: 75%;
  border: 2px solid rgb(228, 233, 220);
  border-radius: 4px;
  background-color: rgb(247, 247, 247);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  z-index: 10;
}
.form-info-input:focus {
  outline-color: rgb(195, 197, 194);
  font-size: 14px;
}
.form-info-input:disabled {
  background-color: rgb(209, 212, 209);
}

.info-form h4 {
  margin-left: 10%;
  color: green;
  position: relative;
  top: 15px;
  font-size: 15px;
}

.btnDivs {
  display: flex;
  justify-content: flex-end;
}

.btnRemove {
  background-color: #e92323;
  margin-top: 30px;
  margin-left: 20px;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  border-radius: 25px;
}
.btnRemove:hover {
  background-color: #a70a0a;
}

.btnEdit {
  background-color: #1a690a;
  margin-top: 30px;
  margin-left: 20px;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  border-radius: 25px;
}
.btnEdit:hover {
  background-color: #084b11;
}

.user-info {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5%;
}

.user-review {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: first baseline;
  height: auto;
  width: 100%;
  background-color: white;
  box-shadow: 1px 1px 1px #888888;
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow: hidden;
  border-radius: 10px;
}
.reviews-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
  left: -22px;
}
.user-review-tabs {
  background-color: white;
  box-shadow: 1px 1px 1px #888888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  overflow: hidden;
  border-radius: 10px;
}

.user-review:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.user-info-item {
  flex: 33%;
  min-width: 320px;
}

.list-review {
  list-style: none;
}
.listitem-review1 {
  font-size: 16px;
  text-align: center;
  padding: 10px;
  color: green;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bolder;
  transition: 0.3s;
}
.listitem-review1:hover {
  font-size: 17px;
  cursor: pointer;
  color: rgb(3, 80, 3);
}
.delete-review {
  border: none;
  cursor: pointer;
  text-align: start;
  font-weight: bold;
  color: red;
  background-color: white;
}
.listitem-review2 {
  font-size: 16px;
  text-align: center;
  padding: 10px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bold;
}
.delete-review2 {
  border: none;
  background-color: white;
  width: 100%;
  padding-top: 6px;
  text-align: start;
  font-weight: bold;
  color: red;
  cursor: pointer;
}
.listitem-review3 {
  background-color: rgba(209, 205, 205, 0.7);
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(219, 177, 86);
  text-align: center;
}
.listitem-review4 {
  font-size: 18px;
  font-weight: bold;
  color: rgb(219, 177, 86);
  text-align: center;
}
.gender {
  position: relative;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  background-color: rgb(235, 234, 234);
  left: 10%;
  border-radius: 25px;
  border-color: rgb(235, 234, 234);

  padding: 5px;
}
select option {
  color: black;
}

.user-review2 {
  display: flex;
  flex-direction: column;
  justify-content: first baseline;
  height: auto;
  width: 70%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 1s;
  margin-bottom: 20px;
  overflow: hidden;
  max-width: 450px;
  max-height: 300px;
  overflow-y: auto;
}
.user-review2::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.user-review2::-webkit-scrollbar-thumb {
  background: rgb(110, 167, 119);
  border-radius: 25px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.user-review2::-webkit-scrollbar-track-piece {
  background-color: #beb8a4;
  opacity: 0.5;
}

.user-review2::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: rgb(110, 167, 119);
}
.user-review2:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
select:active,
select:hover {
  background-color: rgb(218, 218, 218);
  outline: none;
}
.reviewOrganizer {
  color: green;
  margin-left: 30px;
  font-size: 15px;
  transition: 0.5s;
}
.profile-name {
  margin: 0px;
  font-weight: bold;
  color: black;
}
.activity-participants {
  display: flex;
  justify-content: first baseline;
}
.activity-participants:hover,
.activity-organizer:hover {
  background-color: rgb(228, 233, 220);
}
select option:checked,
select option:focus {
  color: green;
}
.profileImageCheckbox {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 5%;
}
.profile-image-organizer,
.profile-image2 {
  border-radius: 100%;
  width: 120px;
  height: 120px;
  transition: 0.5s;
  border: rgb(184, 184, 184) solid 1px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 30px;
}

.profile-image2:hover {
  width: 180px;
  height: 180px;
}
.checkBox {
  color: rgb(187, 27, 27);
  font-weight: bold;
  font-size: 13px;
  grid-column: 1 / 3;
}
/* make it red instead (with with same width and style) */
select:active,
select:hover {
  outline-color: red;
}

.profile-card {
  width: 70%;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
  border-radius: 15px;

  max-width: 350px;
  margin: 0 auto;
  margin-top: 30px;
}
.profile-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.profile-card header {
  display: block;
  background: rgba(255, 255, 255, 1);
  text-align: center;
  overflow: hidden;
}

.profile-card header img {
  border-radius: 50%;
  overflow: hidden;
  width: 50%;

  /*border: 1px solid rgba(255,255,255,.5);*/
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
}
.organizer-activity p {
  font-size: 120%;
}
.profile-card header h1 {
  font-weight: 200;
  font-size: 200%;
  color: #444;
  letter-spacing: -3px;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.profile-card header h2 {
  font-weight: 400;
  font-size: 100%;
  color: #666;
  letter-spacing: 0.5px;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 5px;
}

.profile-card .profile-bio {
  padding: 0 30px;
  text-align: center;
  color: #888;
}

.loader {
  color: #108e29;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.btn2 {
  background-color: grey;
  padding: 10px 18px;
  border-radius: 25px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
.btn2:hover {
  background-color: rgb(104, 102, 102);
}

.btn-icon {
  margin-left: 5px;
}
.item-ceneterd,
.btn-ceneterd {
  display: flex;
  justify-content: center;
}

.btn-ceneterd {
  margin-left: -20px;
}
@media screen and (max-width: 400px) {
  .btnEdit,
  .btnRemove {
    padding: 10px 15px;
    font-size: 10px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 769px) {
  .btnEdit,
  .btnRemove {
    padding: 10px 15px;
    font-size: 12px;
    margin-left: 20px;
  }
}
#wave {
  position: relative;
  height: 70px;
  width: 100%;
  background: #e4e9dc;
}
#wave:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 55%;
  height: 80px;
  background-color: white;
  right: -5px;
  top: 40px;
}
#wave:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 50%;
  height: 70px;
  background-color: #e4e9dc;
  left: 0;
  top: 27px;
}

.join-date {
  font-size: 13px;
  color: black;
  position: relative;
  top: -20px;
}

.ribbon {
  position: relative;
  border-radius: 4px;
  padding: 5px 5px 4px;
  width: 100px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 20px;
  height: 15px;
  font-size: 14px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.27);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  background: linear-gradient(
    to bottom right,
    rgb(149, 165, 136) 0%,
    #333 100%
  );
  cursor: default;
  text-align: center;
  position: relative;
  top: -10px;
  left: -15px;
}
.participant-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 10px;
  margin-left: 10px;
}
.card-profile-organizer {
  display: flex;
  justify-content: center;
}
.organizer-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  margin-left: 25px;
}
.organizer-details h1 {
  font-size: 16px;
  margin-top: 10px;
  position: relative;
  left: -10px;
}
.organizer-details h3 {
  font-size: 24px;
  position: relative;
  top: 30px;
  left: -15px;
  color: grey;
}
.organizer-details h2 {
  color: gold;
  position: relative;
  top: -10px;
  font-size: 19px;
  margin-left: -10px;
}
.image-label-checkbox {
  position: relative;
  top: -18px;
}
.user-active {
  display: flex;
  justify-content: flex-end;
  margin: 0px;
  position: relative;
  top: 15px;
}
.user-active h1 {
  font-size: 15px;
  margin: 0px;
  margin-right: 2%;
  color: grey;
  transition: 0.5s;
}
.user-active h1:hover {
  color: green;
}
.user-active-icon {
  margin-right: 5px;
}
@media screen and (max-width: 500px) {
  .organizer-details h1 {
    font-size: 13px;
  }
  .organizer-details h3 {
    font-size: 20px;
  }
  .organizer-details h2 {
    font-size: 17px;
  }
  .image-label-checkbox,
  .user-active h1,
  .info-form h4,
  .header-text h4,
  .reviewOrganizer,
  .form-info-input {
    font-size: 12px;
  }
}
@media screen and (max-width: 300px) {
  .organizer-details h1 {
    font-size: 11px;
  }
  .organizer-details h3 {
    font-size: 18px;
  }
  .organizer-details h2 {
    font-size: 15px;
  }
  .image-label-checkbox,
  .user-active h1,
  .info-form h4,
  .header-text h4,
  .reviewOrganizer,
  .form-info-input {
    font-size: 10px;
  }
}
.tabs {
  border-radius: 10px;
  margin-bottom: 20px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.inactive-tab {
  font-size: 17px;
  color: grey;
  cursor: pointer;
  opacity: 0.7;
  transition: 1s;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.active-tab {
  font-size: 17px;
  color: green;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px green solid;
  padding-bottom: 5px;
  padding-left: 7px;
  padding-right: 7px;
}
.inactive-tab:hover {
  color: black;
  transform: translateY(-3px);
  font-size: 17px;
}
.no-reviews-text {
  display: flex;
  justify-content: flex-start;
}
.no-reviews-text h1 {
  font-size: 18px;
  color: grey;
  margin-bottom: 30px;
  position: relative;
  left: 5%;
}
.activity-review {
  background-color: white;
  box-shadow: 1px 1px 1px #888888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 350px;
}

.review-info-item {
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
  left: -30px;
}

@media screen and (max-width: 750px) {
  .reviews-grid {
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    left: -22px;
  }
}

@media screen and (max-width: 600px) {
  .tabs {
    border-radius: 10px;
    margin-bottom: 20px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .no-reviews-text h1 {
    font-size: 15px;
  }
  .inactive-tab {
    font-size: 15px;
  }
  .active-tab {
    font-size: 15px;
  }
  .listitem-review3,
  .listitem-review2,
  .listitem-review1 {
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {
  .no-reviews-text h1 {
    font-size: 14px;
  }
  .inactive-tab {
    font-size: 13px;
  }
  .active-tab {
    font-size: 13px;
  }
  .listitem-review3,
  .listitem-review2,
  .listitem-review1 {
    font-size: 14px;
  }
}
@media screen and (max-width: 400px) {
  .no-reviews-text h1 {
    font-size: 12px;
  }
  .inactive-tab {
    font-size: 11px;
  }
  .listitem-review3,
  .listitem-review2,
  .listitem-review1 {
    font-size: 13px;
  }
  .active-tab {
    font-size: 11px;
  }
}
@media screen and (max-width: 900px) {
  .review-info-item {
    grid-template-columns: 100%;
    position: relative;
    left: -25px;
  }
  .activity-review {
    margin: 0 auto;
  }
}
@media screen and (max-width: 400px) {
  .activity-review {
    margin: 0 auto;
    min-width: 100%;
  }
}
