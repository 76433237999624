p {
  font-size: 25px;
  text-align: center;
  padding-top: 20px;
  color: rgb(126, 128, 125);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.user-name-text2,
.user-name-text {
  font-size: 18px;
  transition: 0.3s;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.user-name-text:hover {
  opacity: 0.5;
}
.form-input2 {
  margin-top: 10px;
  display: block;
  height: 25px;
  width: 85%;
  border: 2px solid rgb(228, 233, 220);

  outline: 0;
  background-color: rgb(238, 238, 238);
}

.form-input2:focus {
  outline-color: rgb(0, 0, 0);
  background-color: rgb(247, 247, 247);
}
.search-form {
  width: 100%;
  margin-left: 6%;
}
.search-users {
  margin: 0 auto;
  background-color: white;
  width: 95%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  min-width: 250px;
  margin-top: 30px;
  padding-top: 1px;
  padding-bottom: 45px;
  max-width: 750px;
}
.search-users:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.search-form h4 {
  color: green;
  position: relative;
  top: 15px;
  font-size: 15px;
}
.btns-center {
  display: flex;
  justify-content: center;
}
.btn {
  background-color: #707770;
  margin-top: 30px;
  margin-left: 50px;
  border: none;
  color: white;
  padding: 13px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  border-radius: 25px;
}
.btn:hover {
  background-color: #121612;
}

.profile-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.FlexBtn {
  background-color: #6da56d;
  margin-bottom: 20px;
  border: none;
  color: white;
  padding: 13px 20px;
  text-align: center;
  position: relative;
  top: 7px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  border-radius: 25px;
}
.FlexBtn:hover {
  background-color: #6c8b6c;
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(180, 180, 180, 0.75),
    rgba(0, 0, 0, 0)
  );
}
.userItem {
  display: flex;
  justify-content: space-between;
}
.flip-box-activities,
.flip-box {
  background-color: transparent;
  width: 50px;
  height: 50px;
  margin-right: 80px;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 150px;
  height: 50px;
  text-align: center;
  transition: transform 0.7s;
  transform-style: preserve-3d;
}
.flip-box-activities:hover .flip-box-inner,
.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back {
  background-color: rgb(115, 182, 126);
  border-radius: 25px;
  color: white;
  transform: rotateY(180deg);
}
.chip {
  display: inline-block;
  padding: 0 25px;
  height: 50px;
  width: 95px;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  background-color: #f1f1f1;
}

.chip img {
  float: left;

  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.btn-delete {
  background-color: white;
  margin-top: 30px;
  margin-left: 20px;
  border: none;
  color: red;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 25px;
  border-radius: 10px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.btn-delete:hover {
  border: 1px solid red;

  background-color: red;
  color: white;
}
.btn-no {
  background-color: white;
  margin-top: 30px;
  margin-left: 20px;
  border: none;
  color: green;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 25px;
  border-radius: 10px;
}
.btn-no:hover {
  border: green 1px solid;

  color: white;
  background-color: green;
}
@media screen and (max-width: 769px) {
  .FlexBtn,
  .btn {
    padding: 12px 15px;
    font-size: 12px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 400px) {
  .btnEdit,
  .btnRemove,
  .btn {
    padding: 10px 15px;
    font-size: 10px;
    margin-left: 10px;
  }
}
@media screen and (max-width: 420px) {
  .flip-box {
    background-color: transparent;
    width: 20px;
    height: 20px;
    margin-right: 80px;
  }
  .user-name-text {
    margin-right: 20px;
  }
}
.text-wrapper2,
.text-wrapper {
  position: relative;
  top: -5px;
}
@media screen and (max-width: 500px) {
  .flip-box-activities {
    display: none;
  }
  .text-wrapper2 {
    margin-left: 30px;
  }
  .user-name-text {
    margin-right: 20px;
    font-size: 14px;
  }
}
INPUT[type="checkbox"]:focus {
  outline: 1px solid rgba(0, 0, 0, 0.2);
}

INPUT[type="checkbox"] {
  background-color: #ddd;
  border-radius: 2px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 17px;
  cursor: pointer;
  position: relative;
  top: 5px;
}

INPUT[type="checkbox"]:checked {
  background-color: rgb(220, 233, 222);
  background: rgb(9, 163, 68)
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    3px 3px no-repeat;
}
.check-boxes label {
  color: green;
  position: relative;
  font-weight: bold;
}
.check-boxes {
  position: relative;
  top: 20px;
  display: flex;
  justify-content: space-around;
}
.user-search-btns button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: grey;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  border: none;
  margin-right: 20px;
  margin-bottom: 5px;
}
.user-search-btns button:hover {
  background-color: #121612;
}
.user-search-btns {
  position: relative;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 35px;
  left: -5%;
}
@media screen and (max-width: 750px) {
  .check-boxes {
    display: block;
    top: 20px;
    left: 5%;
  }
}
@media screen and (max-width: 450px) {
  .search-form h4 {
    color: green;
    position: relative;
    top: 15px;
    font-size: 12px;
  }
  .check-boxes label {
    font-size: 12px;
  }
  .user-search-btns button {
    margin-top: 10px;
    padding: 7px 10px;
    background-color: grey;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    border: none;
    margin-right: 20px;
    margin-bottom: 5px;
    font-size: 12px;
  }
  .flip-box-back {
    display: none;
  }
}
@media screen and (max-width: 320px) {
  .userItem {
    margin: 0 auto;
  }
  .user-name-text {
    font-size: 11px;
  }
  .FlexBtn {
    padding: 5px 5px;
    font-size: 10px;
  }
}
