.logoContainer {
  display: flex;
}
@font-face {
  src: url(../fonts/Fontmachine\ -\ Ghino-Extrabold.otf);
  font-family: ghinobold;
}
.logo {
  margin-top: 50px;
}
.logo2 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.circles {
  display: flex;
}
.top {
  margin-left: 30px;
  font-size: 70px;
  letter-spacing: -0.15cm;
}
.circle {
  background: #0d7d71 68%;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin-right: 5px;
  transform: translate(60px, 25px);
}
span {
  font-size: 35px;
  line-height: 62px;
  font-family: ghinobold;
  background: rgb(23, 160, 144);
  background: linear-gradient(
    90deg,
    rgba(23, 160, 144, 1) 0%,
    rgba(19, 150, 134, 1) 38%,
    rgba(13, 125, 113, 1) 68%,
    rgba(2, 102, 90, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}

body {
  background-color: rgb(228, 233, 220);
  margin: 0;
  padding: 0;
}

.center {
  display: flex;
  justify-content: center;
}
.login-form {
  height: auto;
  padding-bottom: 20px;
  width: 70%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  margin-top: 50px;
  max-width: 500px;
  margin-bottom: 30px;
}

.login-form p {
  font-size: 25px;
  text-align: center;
  padding-top: 20px;

  color: rgb(126, 128, 125);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
hr {
  width: 50%;
  text-align: center;
  margin-left: 25%;
}
.form-input {
  margin-left: 11%;
  margin-top: 20px;
  display: block;
  height: 25px;
  width: 75%;
  border: 2px solid rgb(228, 233, 220);
  border-radius: 4px;
  background-color: rgb(247, 247, 247);
}
.form-input:focus {
  outline-color: rgb(195, 197, 194);
}
.login-form h4 {
  margin-left: 12%;
  color: green;
  position: relative;
  top: 15px;
  font-size: 15px;
}
.btn {
  background-color: #707770;
  margin-top: 30px;
  margin-left: 50px;
  border: none;
  color: white;
  padding: 13px 35px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  border-radius: 25px;
}
.btn:hover {
  background-color: #121612;
}
.error-message {
  color: red;
  margin-left: 50px;
}
.success-message {
  color: green;
  margin-left: 50px;
}
.dashboard-options {
  border-radius: 25px;
  display: flex;
  justify-content: space-evenly;
}
.dashboard-options h5 {
  color: rgb(3, 156, 28);
  cursor: pointer;
  margin-left: 20px;
  font-size: 16px;
  transition: 1s;
}
.dashboard-options h5:hover {
  opacity: 0.7;
}
.center-txt {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .login-form {
    width: 70%;
  }
}
@media screen and (max-width: 520px) {
  .login-form {
    width: 95%;
  }
}
@media screen and (max-width: 450px) {
  .login-form {
    width: 95%;
  }
  .login-form p {
    font-size: 20px;
    text-align: center;
    padding-top: 20px;

    color: rgb(126, 128, 125);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }
  .login-form h4 {
    font-size: 12px;
  }
}
