.a {
  background-color: rgb(0, 0, 0);
  height: 40px;
  width: 100%;
  min-width: 250px;
}
.logo {
  display: flex;
  justify-content: center;
}
.navBarItems {
  margin: 0;
  list-style: none;
  display: flex;
}
.listitem1 {
  margin-left: auto;
  margin-top: 5px;
}
.listitem2 {
  margin-right: 20px;
  margin-top: 5px;
}
.button1 {
  border-radius: 25px;
  background-color: rgb(0, 0, 0);
  color: white;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 17px;
  padding-top: 5px;
  cursor: pointer;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  transition: 1s;
  width: 80px;
  display: flex;
}
.button1:hover {
  transform: translateX(6px);
  opacity: 0.7;
}
.hamburger-icon {
  background-color: rgb(0, 0, 0);
  color: white;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 17px;
  padding-top: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  border: none;
  display: none;
}
.hamburger-icon:hover {
  opacity: 0.7;
}
.button2 {
  background-color: rgb(0, 0, 0);
  color: white;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 17px;
  padding-top: 5px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  border: none;
}
.button2:hover {
  color: rgb(173, 174, 180);
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.dropdown {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: relative;
  top: 8px;
  left: -30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 100000;
}
.dropdown button {
  background-color: rgb(0, 0, 0);
  padding: 10px 20px;
  border: none;
  color: rgb(206, 205, 205);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 17px;
  padding-top: 5px;
}
.dropdown button:hover {
  background-color: rgb(228, 233, 220);
  padding: 10px 20px;
  border: none;
  color: black;
}
.text-styling {
  color: grey;
  font-size: 15px;
  padding: 5px;
  margin: 0px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media screen and (max-width: 800px) {
  .hamburger-icon {
    display: flex;
  }
  .button1 {
    display: none;
  }
  .button2 {
    display: none;
  }
}
.logout-icon {
  margin-left: 10px;
  position: relative;
  top: 2px;
}
