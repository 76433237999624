.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  --background: #ffffff;
  --text: green;
  position: relative;
  height: 150px;
  box-shadow: 0 0 2rem -1rem rgba(0, 0, 0, 0.05);
  margin-bottom: 0px;
  margin-top: 100px;
  width: 150px;
  margin-left: 40px;
  margin-right: 40px;
  transition: 1s;
}

.card .multi-button {
  z-index: 0;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
}
.card .multi-button button {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: none;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
  color: green;
  font-size: 18px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.card .multi-button button:hover {
  font-size: 21px;
  width: 2.5rem;
  height: 2.5rem;
  opacity: 0.7;
}
body .card .multi-button button:first-child:nth-last-child(2):nth-child(1),
body .card .multi-button button:first-child:nth-last-child(2) ~ *:nth-child(1) {
  left: 37.5%;
  top: 18.75%;
}
body .card .multi-button button:first-child:nth-last-child(2):nth-child(2),
body .card .multi-button button:first-child:nth-last-child(2) ~ *:nth-child(2) {
  left: 18.75%;
  top: 37.5%;
}
body .card .container2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: white;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

body .card:hover .multi-button,
body .card {
  width: 10rem;
  height: 10rem;
}
.container2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.container2 img {
  width: 90%;
  height: 100px;
  margin-top: 10px;
  border-radius: 10px;
}
.container2 h1 {
  font-size: 15px;
  margin-top: 20px;
  color: green;
}
.btn-left {
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
}
