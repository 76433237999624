.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .file-upload {
  height: 150px;
  width: 150px;
  border-radius: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #ffffff;
  overflow: hidden;
  background-image: linear-gradient(
    to bottom,
    green 50%,
    rgb(228, 233, 220) 50%
  );
  background-size: 100% 200%;
  transition: all 1s;
  color: #ffffff;
  font-size: 100px;
}
.wrapper .file-upload input[type="file"] {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.wrapper .file-upload:hover {
  background-position: 0 -100%;
  color: green;
}
.image-review {
  display: flex;
  justify-content: center;
}
.image-review img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.edit-interest-form {
  margin-top: 50px;
  position: relative;
  height: auto;
  padding-bottom: 20px;
  width: 95%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 500px;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 50px;
}
.edit-interest-form h4 {
  margin-left: 55px;
  color: green;
  position: relative;
  top: 15px;
  font-size: 15px;
}
@media screen and (max-width: 450px) {
  .edit-interest-form h4 {
    margin-left: 55px;
    color: green;
    position: relative;
    top: 15px;
    font-size: 12px;
  }
  .edit-interest-form p {
    font-size: 16px;
  }
  .wrapper .file-upload {
    height: 100px;
    width: 100px;
    border-radius: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #ffffff;
    overflow: hidden;
    background-image: linear-gradient(
      to bottom,
      green 50%,
      rgb(228, 233, 220) 50%
    );
    background-size: 100% 200%;
    transition: all 1s;
    color: #ffffff;
    font-size: 70px;
  }
  .image-review img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}
@media screen and (max-width: 500px) {
  .adjust-txt-position {
    position: relative;
    left: -2%;
  }
}
@media screen and (max-width: 350px) {
  .edit-interest-form h4 {
    margin-left: 55px;
    color: green;
    position: relative;
    top: 15px;
    font-size: 11px;
  }
  .edit-interest-form p {
    font-size: 15px;
  }
  .wrapper .file-upload {
    height: 80px;
    width: 80px;
    border-radius: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #ffffff;
    overflow: hidden;
    background-image: linear-gradient(
      to bottom,
      green 50%,
      rgb(228, 233, 220) 50%
    );
    background-size: 100% 200%;
    transition: all 1s;
    color: #ffffff;
    font-size: 50px;
    margin-top: 5px;
  }
  .image-review img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-top: 5px;
  }
}
