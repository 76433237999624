.charts,
.statsCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}
.statsCard {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 20%;
  display: flex;
  justify-content: space-between;
  min-width: 320px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.logins-chart,
.chart2,
.chart {
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 20%;
  display: flex;
  min-width: 480px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.logins-chart,
.chart2 {
  flex-direction: column;
  height: 258px;
}
.logins-chart p,
.chart2 p {
  position: relative;
  top: -50px;
  font-size: 22px;
}
.logins-chart {
  width: 85%;
  height: 100%;
}
.bar-chart {
  position: relative;
  top: -10px;
  left: -10px;
}
.line-chart {
  position: relative;
  height: 250px;
  left: -10px;
  top: 15px;
}
.statsCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.statsIcon {
  font-size: 70px;
  align-self: center;
  position: relative;
  left: 20px;
}
.statsInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  right: 20px;
}
.statsInfo h1 {
  font-size: 18px;
  position: relative;
  top: -25px;
  font-weight: normal;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.statsInfo h2 {
  font-weight: bold;
  font-size: 30px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.statsInfo h3 {
  font-size: 13px;
  position: relative;
  top: -30px;
  padding: 0px;
  margin: 0px;
  color: grey;
}
.legend {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  width: 250px;
  position: relative;
  left: -10%;
  padding: 15px;
  text-align: center;
  cursor: default;

  border-radius: 0.5rem;
  border-left: 0 solid #a0a0a0;
  transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;
}
.legend2 {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  width: 200px;
  margin-left: auto;
  text-align: center;
  font-size: 10px;
  position: relative;
  top: -12px;
  border-radius: 10px;
  color: grey;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 0.5rem;
  border-left: 0 solid #a0a0a0;
  transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;
  cursor: pointer;
}

.legend:hover,
.legend2:hover {
  padding-left: 0.5rem;
  border-left: 0.5rem solid rgb(52, 182, 91);
  background-color: rgba(236, 232, 232, 0.5);
  opacity: 0.7;
}

.legend h1 {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  margin-bottom: 10px;
}
.legend p {
  font-size: 22px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 20px;
}
.legend-icon {
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.legend-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 20%;
}
@media screen and (max-width: 600px) {
  .chart2,
  .chart {
    min-width: 300px;
  }
  .legend {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    width: 200px;
    position: relative;
    left: -5%;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    border-left: 0 solid #a0a0a0;
    transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;
  }
  .legend h1 {
    font-size: 13px;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }
  .legend p {
    font-size: 17px;
    padding: 0px;
    margin: 0px;
    margin-bottom: 20px;
  }
  .legend2 {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    width: 50%;
    margin-left: auto;
    text-align: center;
    font-size: 7px;
    position: relative;
    top: -5px;
    border-radius: 10px;
    color: grey;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .chart2 {
    flex-direction: column;
    height: 180px;
  }
  .chart2 p {
    position: relative;
    top: -50px;
    font-size: 22px;
  }
  .bar-chart {
    position: relative;
    top: 10px;
  }
}
.sos-notification {
  margin: 20px;
  background-color: #e0eff6;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;

  border-left: 0 solid #a0a0a0;
  transition: border-left 300ms ease-in-out, padding-left 300ms ease-in-out;
}
.sos-notification:hover {
  padding-left: 0.5rem;
  border-left: 0.5rem solid rgb(64, 173, 224);
}
.total-logins {
  font-size: 17px;
  margin: 0px;
  padding: 0px;
  text-align: center;

  padding: 5px;
  margin-bottom: auto;
  margin-right: auto;
  color: white;
  cursor: default;
  background-color: rgb(143, 141, 141);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
