.reviews-options-wrapper {
  display: flex;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  flex-wrap: wrap;
}

.reviews-option2,
.reviews-option {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: 1s;
  padding-left: 25px;
  padding-right: 25px;
  width: 30%;
  min-width: 250px;
  margin-bottom: 30px;
}
.reviews-option:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translate(-5px, -5px);
}

.to-appear {
  position: relative;
  top: -10px;
  opacity: 0;
  transition: 1s;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.reviews-option:hover .to-appear {
  opacity: 0.9;
  font-size: 15px;
}
.reviews-option:hover .reviews-icon {
  top: 10px;
}
.reviews-option:hover .to-go-up {
  opacity: 0.9;
  top: 15px;
}
.reviews-icon2,
.reviews-icon {
  width: 150px;
  fill: red;
  position: relative;
  top: 30px;
  transition: 1s;
}
.to-go-up {
  position: relative;
  color: grey;
  top: 40px;
  font-size: 19px;
  transition: 1s;
  font-family: Arial, Helvetica, sans-serif;
}
.reviews-option h1 {
  font-size: 16px;
  position: relative;
  top: 15px;
}
.reviews-option button {
  padding: 10px 15px;
  background-color: red;
  border-radius: 20px;
  border: none;
  color: white;
  position: relative;
  top: 25px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.reviews-option .check-activities-reviews {
  padding: 10px 15px;
  background-color: green;
  border-radius: 20px;
  border: none;
  color: white;
  position: relative;
  top: 25px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
select::-ms-expand {
  display: none;
  outline: none;
}
#mySelect {
  outline: none;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 2em 0.5em 0.5em;
  border: 1px solid #eee;
  font: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: right 15px top 1em, right 10px top 1em;
  background-size: 5px 5px, 5px 5px;
  border-bottom: 0px solid green;
}
#mySelect:hover {
  border-bottom: 2px solid green;
}
.select-input {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.reviews-box {
  display: grid;
  background-color: rgb(255, 255, 255, 0.3);
  border-radius: 10px;
  grid-template-columns: 49% 49%;
  margin-top: 20px;
}
.from-to-review {
  display: flex;
  justify-content: center;
}
.user-review3 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 1s;
  overflow: hidden;
}
.user-review3:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.list-review2 {
  list-style: none;
  position: relative;
  left: -12px;
}
@media screen and (max-width: 400px) {
  .reviews-option {
    min-width: 200px;
    width: 200px;
    height: 285px;
  }
  .reviews-option h1 {
    font-size: 13px;
    position: relative;
    top: 15px;
  }
  .reviews-option:hover .to-appear {
    opacity: 0.9;
    font-size: 13px;
  }
}
@media screen and (max-width: 750px) {
  .reviews-box {
    grid-template-columns: 100%;
    margin-top: 20px;
  }
  .list-review2 {
    list-style: none;
    position: relative;
    left: -18px;
  }
}
