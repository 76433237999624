button:focus {
  outline: 0;
}
button {
  transition: 0.5s;
}
* {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
